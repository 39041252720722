import React from "react";

import { IMessageLast } from "../types/chat";

interface ChatListButtonProps {
  name: string;
  avatar: any;
  isActive?: boolean;
  unreadMessages?: number;
  lastMessage: IMessageLast | null;
  onClick: (evt: any) => void;
  isSupport: boolean;
}

const ChatListButton = ({ name, avatar, isActive, unreadMessages, lastMessage, onClick, isSupport }: ChatListButtonProps) => {
  return (
    <div
      className={`relative flex items-center gap-[10px] w-[307px] min-h-[58px] pl-[10px] pr-[50px] rounded-[10px] border hover:border-[#006CFB] cursor-pointer ${!!unreadMessages ? 'bg-[#006CFB0D]' : 'bg-[#F5F5F566]'} ${isActive ? 'border-[#006CFB]' : 'border-transparent'}`}
      onClick={onClick}
    >
      <div className="h-[36px] w-[36px]">
        {avatar?.url ? (
          <img
            src={avatar.url}
            width={36}
            height={36}
            alt="user icon"
            className="h-[36px] min-w-[36px] rounded-[12px] object-cover"
          />
        ) : (
          <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{name?.charAt(0)}</div>
        )}
      </div>
      <div className="text-[14px] leading-[1]">
        <div className={`text-[#2D3748] font-bold mb-[3px] truncate ${isSupport ? 'text-[#006CFB]' : 'text-[#2D3748]'}`}>{name}</div>
        <div className="text-[#718096] truncate">{lastMessage?.body || ''}</div>
      </div>
      {!!unreadMessages && (
        <div className="absolute top-1/2 right-4 transform -translate-y-1/2 flex-center w-5 h-5 bg-[#DF5753] rounded-full text-white text-[10px]">{unreadMessages}</div>
      )}
    </div>
  )
}

export default ChatListButton;
