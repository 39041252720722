import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useForgotPasswordStore from "../../features/forgot-password/stores/useForgotPasswordStore";

import Button from "../../common/components/Button/Button";

import ForgotPasswordEmailForm from "../../features/forgot-password/components/ForgotPasswordEmailForm";
import ForgotPasswordPinForm from "../../features/forgot-password/components/ForgotPasswordPinForm";
import ForgotPasswordNewPasswordForm from "../../features/forgot-password/components/ForgotPasswordNewPasswordForm";

import Logo from "../../common/assets/images/Logo";
import Success from "../../common/assets/images/Success";
import ChevronIcon from "../../common/assets/icons/ChevronIcon";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { step, setStep } = useForgotPasswordStore((state) => ({...state}));

  const stepNumber = useMemo(() => {
    switch (step) {
      case "email":
        return 1;
      case "pin":
        return 2;
      case "new-password":
        return 3;
    }
  }, [step]);

  const stepHeader = useMemo(() => {
    switch (step) {
      case "email":
        return t("reset_password_header");
      case "pin":
        return t("verify_code_header");
      case "new-password":
        return t("new_password_header");
    }
  }, [step, t]);

  const stepDescription = useMemo(() => {
    switch (step) {
      case "pin":
        return t("verify_code_description");
      case "new-password":
        return t("new_password_description");
      default:
        return t("forgot_password_description");
    }
  }, [step, t]);

  const handleBackClick = () => {
    switch (step) {
      case "email":
        navigate("/");
        break;
      case "pin":
        setStep("email");
        break;
      case "new-password":
        setStep("pin");
        break;
    }
  }

  return (
    <div className="flex flex-col items-center pt-[50px] relative overflow-y-auto h-full pb-[50px]">
      {step !== "success" && (
        <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
          <div className="fixed">
            <div className="flex items-center gap-1">
              <ChevronIcon/>
              <div>{t("back")}</div>
            </div>
          </div>
        </div>
      )}
      <div className="w-[160px] h-[56px]">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      {step !== "success" && (
        <>
          <div className="flex flex-col items-center pt-[54px]">
            <div className="font-bold text-[#AAAAAA80]">{stepNumber} / 3</div>
            <h2 className="text-2xl font-bold mt-0.5">{stepHeader}</h2>
            <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4 w-[508px]">{stepDescription}</div>
          </div>
          <div className="mt-[30px]">
            {step === "email" && <ForgotPasswordEmailForm/>}
            {step === "pin" && <ForgotPasswordPinForm/>}
            {step === "new-password" && <ForgotPasswordNewPasswordForm/>}
          </div>
        </>
      )}
      {step === "success" && (
        <div className="flex flex-col items-center flex-1">
          <div className="mt-[64px]">
            <Success/>
          </div>
          <div className="text-[32px] text-[#26203b] mt-[32px]">{t("password_reset_success")}</div>
          <div className="text-[20px] text-[#9c9aa5] mt-[10px] w-[662px] text-center">
            {t("password_reset_success_description")}
          </div>
          <div className="w-[352px] mt-auto">
            <Link to="/">
              <Button size="large" fullWidth={true}>
                {t("back_to_sign_in")}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordPage;
