import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

import { postOtpPasswordResetSend } from "../../../api/services/otp/requests";

import { IForgotPasswordEmail } from "../types/form";

import { forgotPasswordEmailFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useForgotPasswordStore from "../stores/useForgotPasswordStore";

const useFormForgotPasswordEmail = () => {
  const { t } = useTranslation();

  const { setStep, setEmail } = useForgotPasswordStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm<IForgotPasswordEmail>({
    mode: "onBlur",
    defaultValues: {email: ''},
    resolver: zodResolver(forgotPasswordEmailFormSchema),
  });

  const onSubmit = async (value: IForgotPasswordEmail) => {
    try {
      setLoader(true);
      await postOtpPasswordResetSend({email: value.email});
      setEmail(value.email);
      setStep("pin");
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'))
    } finally {
      setLoader(false);
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  }
}

export default useFormForgotPasswordEmail;
