import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { postPaymentCheckout } from "../../api/services/payment/requests";

import { addPriceDelimiters } from "../../common/utils/helpers";

import { IPostPaymentCheckout } from "../../api/services/payment/types";

import useAuthStore from "../../common/stores/useAuthStore";
import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

import Button from "../../common/components/Button/Button";

import TransactionsHistory from "../../features/balance/components/TransactionsHistory";

import TransactionSuccessModal from "../../features/balance/components/modals/TransactionSuccessModal";
import TransactionFailedModal from "../../features/balance/components/modals/TransactionFailedModal";

//todo: make components
const BalancePage: React.FC = () => {
  const [amount, setAmount] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { loader, setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const handleCheckout = async () => {
    if (loader) return;

    try {
      setLoader(true);

      const payload: IPostPaymentCheckout = {
        quantity: amount,
      }

      const data = await postPaymentCheckout(payload);
      if (data?.length) {
        window.location.assign(data);
      } else {
        console.error("Something went wrong");
      }
      setLoader(false);
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
      setLoader(false);
    }
  }

  useEffect(() => {
    const status = searchParams.get('transactionStatus');

    if (status) {
      const isSuccess = status.includes('success');

      if (isSuccess) {
        setChildren(<TransactionSuccessModal />);
      } else {
        setChildren(<TransactionFailedModal />);
      }

      setIsOpen(true);
      searchParams.delete('transactionStatus');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <div className="p-[18px_25px_26px_25px]">
      <div className="py-5 px-[38px] rounded-[15px] bg-white">
        <div className="flex items-center gap-[40px] mb-[45px]">
          <div className="w-[416px] p-5 bg-[#F2F8FF59]">
            <div className="mb-[25px]">
              <div className="text-[18px] text-[#2A2A2A] font-medium mb-[5px]">{t('top_up')}</div>
              <div className="text-[12px] text-[#A0AFC4]">{t('top_up_desc')}</div>
            </div>
            <div className="flex items-center gap-[10px]">
              <div className="flex items-center gap-[5px]">
                <NumericFormat
                  thousandSeparator=" "
                  onValueChange={(values: any) => {
                    const { value } = values;
                    setAmount(value)
                  }}
                  value={amount}
                  id="amount"
                  step={1}
                  className="w-[112px] h-[27px] text-[14px] border border-[#006CFB] rounded-[4px] text-[#666] text-center outline-none"
                  placeholder="25 000"
                />
                <div className="text-[12px] text-[#A0AFC4]">Kč</div>
              </div>
              <Button onClick={handleCheckout} disabled={!amount.length} className="flex-center w-[86px] h-[29px] rounded-[8px] text-[12px] p-0">{t('pay')}</Button>
            </div>
          </div>
          <div>
            <div className="text-[14px] font-bold mb-[10px] font-Manrope">{t('current_balance')}:</div>
            <div className="text-[28px] text-[#006CFB] font-semibold font-Manrope">{addPriceDelimiters(user?.balance)} Kč</div>
          </div>
        </div>

        <TransactionsHistory />
      </div>
    </div>
  )
}

export default BalancePage;
