import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getProductAvailableCount } from "../../../../../api/services/product/requests";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Select from "../../../../../common/components/Select/Select";
import Button from "../../../../../common/components/Button/Button";

import usePublishModalForm from "../../../hooks/usePublishModalForm";

interface IPublishModalFormProps {
  id: string;
}

const PublishModalForm: React.FC<IPublishModalFormProps> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const { data: { publications } = {} } = useQuery(["publications"], async () => {
      setLoader(true);

      const data = await getProductAvailableCount();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const {
    control,
    watch,
    handleSubmit,
  } = usePublishModalForm({ id })

  const durationValue = watch("duration");

  const cost = 1;
  const amount = publications || 0;
  const price = cost * durationValue.value;
  const notEnoughCash = amount - price < 0;

  const options = Array.from({ length: 36 }, (_, i) => ({
    label: `${i + 1} ${i === 0 ? t('month') : t('months')}`,
    value: i + 1
  }));

  const handleProductsRedirect = () => {
    navigate('/products');
    onClose();
  }

  const onSubmit = () => {
    handleSubmit().then(() => {
      onClose();
      navigate('/properties');
    })
  }

  return (
    <div className="grid gap-5 w-[572px]">
      <div className="flex justify-between items-center">
        <div className="text-[#333] text-[18px] leading-5 font-bold">{t('publish_property')}</div>
        <div className="flex-center gap-[10px] p-[6px] rounded-[10px] bg-blue text-[18px] text-white font-bold">
          <div>{t('Publications')}: {amount}</div>
        </div>
      </div>
      <div>{t('publication_property_desc')}</div>
      <div>
        <div className="mb-[6px] text-[#333] text-[14px]">{t('publication_duration')}</div>
        <Select classNames={{
          container: () => 'w-[358px] h-[50px] rounded-[15px] border border-[#E2E8F0] leading-[18px]',
          control: () => 'w-[358px] h-[50px] p-4',
          menu: () => 'bg-white p-4 pr-1 mt-1 rounded-[15px] border border-[#E2E8F0] ',
          menuList: () => 'max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6',
          singleValue: () => 'text-[#666] text-[14px]'
        }} control={control} name="duration" options={options} unstyled />
      </div>
      <div className="h-[50px] flex justify-end items-end">
        <div className="flex items-center gap-[34px]">
          <div className="text-[#666666]">{t('amount')} (<span>{durationValue.label}</span>)</div>
          <div className={`flex items-center gap-[4px] lowercase ${notEnoughCash ? 'text-[#E53E3E]' : 'text-[#333]'}`}>{`${price} ${price > 1 ? t('publications') : t('publication')}`}</div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        {notEnoughCash && (
          <div className="cursor-pointer text-blue px-[18px]" onClick={handleProductsRedirect}>
            {t('buy_publications')}
          </div>
        )}
        <Button className="h-[48px] rounded-[8px] text-[16px] font-bold" disabled={notEnoughCash} onClick={onSubmit}>{t('submit_for_moderation')}</Button>
      </div>
    </div>
  );
};

export default PublishModalForm;
