import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ICategoryFull } from "../../common/types/dto/category";

import { IPostPropertyPayload } from "../../api/services/property/types";
import { getCategoryList } from "../../api/services/category/requests";
import { postProperty } from "../../api/services/property/requests";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";

import usePropertyEditStore from "../../features/property/stores/usePropertyEditStore";

import PageHeader from "../../common/components/PageHeader/PageHeader";
import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import Button from "../../common/components/Button/Button";

import PropertyRadioCategoryField
  from "../../features/property/components/PropertyRadioCategoryField";

import {
  getCategoryFromCategoriesIndex,
  initCategoriesIndexFromCategoryTree
} from "../../features/property/helpers/category";

function moveDescription(node: any) {
  if (!node.children || node.children.length === 0) {
    return;
  }

  const firstChild = node.children[0];
  if (node.description && firstChild) {
    firstChild.description2 = node.description;
  }

  node.children.forEach((child: any) => moveDescription(child));
}

const PropertyCreatePage = () => {
  const [selectedCategoriesIndex, setSelectedCategoriesIndex] = useState<number[]>([]);
  const [currentLayer, setCurrentLayer] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { setFlow } = usePropertyEditStore();

  const { data: categories } = useQuery(["category"], async () => {
    setLoader(true);
    const data = await getCategoryList();
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const { mutateAsync, isLoading } = useMutation(async (payload: IPostPropertyPayload) => {
    setLoader(true);
    const data = await postProperty(payload);
    setLoader(false);
    return data;
  });

  categories?.forEach(item => moveDescription(item));

  const setLayerValue = (layer: number, index: number, childrenLength: number) => {
    setSelectedCategoriesIndex((prevState) => {
      const array = prevState.slice(0, layer);
      array[layer] = index;
      setCurrentLayer(layer + 1)
      return initCategoriesIndexFromCategoryTree(array, getCategoryFromCategoriesIndex(categories as ICategoryFull[], array));
    })
    if (childrenLength) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  };

  const handleCreateProperty = async () => {
    const category = getCategoryFromCategoriesIndex(categories as ICategoryFull[], selectedCategoriesIndex)
    const { data } = await mutateAsync({
      categoryId: category.id
    }, {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });
    setFlow('create');
    navigate(`/property/${data.id}/edit`);
  };

  useEffect(() => {
    if (!categories || categories.length === 0) return;
    setSelectedCategoriesIndex(initCategoriesIndexFromCategoryTree([0], categories[0]))
  }, [categories]);

  return (
    <div className="p-[20px_18px_16px_40px] min-h-full">
      <PageHeader
        backButton="/properties"
        start={t('create_property')}
      />
      <CardWrapper className="h-full flex flex-col overflow-x-auto pr-[13px] mt-[20px]">
        <>
          {!!categories && selectedCategoriesIndex.length > 0 && (
            <div className="flex flex-col gap-[35px] mb-[40px]">
              {selectedCategoriesIndex.map((index, layer) => {
                const options = layer === 0 ? categories as any : getCategoryFromCategoriesIndex(categories as any, selectedCategoriesIndex.slice(0, layer)).children;
                const title = layer === 0 ? t('select_property_type') : undefined;
                return layer <= currentLayer && (
                  <PropertyRadioCategoryField
                    key={layer}
                    title={title}
                    description={options[0]?.description2 || t('transaction_type')}
                    options={options}
                    setField={setLayerValue}
                    layer={layer}
                    selectIndex={index}
                    currentLayer={currentLayer}
                  />
                )
              })}
            </div>
          )}

          <div className="flex justify-end items-center mt-[23px]">
            <Button
              color="blue"
              size="large"
              className="w-[318px]"
              disabled={isLoading || disabled}
              onClick={handleCreateProperty}
            >
              {t('create_property')}
            </Button>
          </div>
        </>
      </CardWrapper>
    </div>
  );
}

export default PropertyCreatePage;
