import { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

import useFormForgotPasswordNewPassword from "../hooks/useFormForgotPasswordNewPassword";

import PasswordStrength from "../../create-account/components/PasswordStrength";

const ForgotPasswordNewPasswordForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const {
    control,
    isValid,
    password,
    handleSubmit,
  } = useFormForgotPasswordNewPassword();

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-[318px] flex flex-col gap-6">
        <Input
          type="password"
          label={t("password_label")}
          name="password"
          control={control}
          placeholder={t("new_password_placeholder")}
        />
        <PasswordStrength
          password={password}
          setValidPassword={setValidPassword}
        />
        <Input
          type="password"
          label={t("confirm_password_label")}
          name="confirmPassword"
          control={control}
          placeholder={t("confirm_password_placeholder")}
        />
      </div>
      <div className="flex-center mt-[46px]">
        <Button
          type="submit"
          size="large"
          disabled={!isValid || !validPassword}
          fullWidth={true}
        >
          {t("reset_password")}
        </Button>
      </div>
    </form>
  );
}

export default ForgotPasswordNewPasswordForm;
