import React, {ReactNode} from "react";
import classNames from "classnames";
//todo remove ts ignore
// @ts-ignore
import styles from "./Button.module.css";

interface IButton {
  children?: ReactNode;
  type?: "button" | "submit";
  color?: "blue" | "white" | "red";
  size?: "small" | "medium" | "large";
  className?: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}

const Button =
  ({
     children,
     type = "button",
     color = "blue",
     size = "medium",
     className,
     fullWidth,
     onClick,
     disabled,
   }: IButton) => {

    return (
      <button
        type={type}
        className={classNames(
          styles["button"],
          styles[`button-${color}`],
          styles[`button-${size}`],
          {
            "w-full": fullWidth,
            [`${className}`]: !!className,
          }
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }

export default Button;
