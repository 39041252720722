import React from 'react';
import { useTranslation } from "react-i18next";

import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";
import Input from "../../../../../common/components/Input/Input";

import useFormChangeEmail from "../../../hooks/useFormChangeEmail";

import ChangeEmailOTPModalForm from "./ChangeEmailOTPModalForm";

const ChangeEmailModalForm: React.FC = () => {
  const { t } = useTranslation();

  const { setChildren } = useGlobalModalStore();

  const openOTPModal = () => {
    setChildren(<ChangeEmailOTPModalForm />)
  }

  const {
    control,
    handleSubmit,
    isValid,
  } = useFormChangeEmail(openOTPModal);

  return (
      <form onSubmit={handleSubmit} className="grid gap-6 w-[572px]">
        <div>
          <h2 className="text-[#333] text-[18px] leading-5 font-medium mb-[10px]">{t('change_email')}</h2>
          <div className="text-[14px] text-[#666]">{t('enter_new_email_desc')}</div>
        </div>
        <Input
          label={t('email')}
          name="email"
          control={control}
          placeholder={t('enter_new_email')}
        />
        <Button
          className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0 ml-auto"
          type="submit"
          disabled={!isValid}
        >{t('update_email')}</Button>
      </form>
  );
};

export default ChangeEmailModalForm;
