import { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";

import useFormCreateAccountBase from "../hooks/useFormCreateAccountBase";

import PasswordStrength from "./PasswordStrength";

const CreateAccountBaseForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    password,
    isValid,
  } = useFormCreateAccountBase();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-between items-center w-[1206px]">
        <div className="w-[400px] h-[510px] p-5 bg-[#F2F8FF59] ">
          <h3 className="text-[24px] text-[#333333] leading-[34px] mb-5">{t('company_information')}</h3>
          <div className="grid gap-[25px]">
            <Input
              label={t('legal_name')}
              name="legalName"
              control={control}
              placeholder={t('enter_legal_name')}
            />
            <Input
              label={t('ic')}
              name="ic"
              control={control}
              placeholder={t('enter_ic')}
            />
            <Input
              label={t('company_address')}
              name="address"
              control={control}
              placeholder={t('enter_address')}
            />
            <PhoneInput
              label={t('company_phone_number')}
              name="companyPhone"
              control={control}
              defaultCountry="CZ"
              placeholder={t('enter_phone_number')}
            />
          </div>
        </div>
        <div>
          <h3 className="text-[24px] text-[#333333] leading-[34px]">{t('personal_information')}</h3>
          <div className="text-[14px] text-[#666666] leading-5 mb-5">{t('personal_information_description')}</div>
          <div className="grid grid-cols-2 gap-y-6 gap-x-7 w-[752px]">
            <Input
              label={t('full_name')}
              name="name"
              control={control}
              placeholder={t('enter_full_name')}
            />
            <Input
              type="password"
              label={t('password')}
              name="password"
              control={control}
              placeholder={t('enter_new_password')}
            />
            <Input
              label={t('email')}
              name="email"
              control={control}
              placeholder={t('enter_email')}
            />
            <PasswordStrength
              password={password}
              setValidPassword={setValidPassword}
            />
            <PhoneInput
              label={t('phone_number')}
              name="phone"
              control={control}
              defaultCountry="CZ"
              placeholder={t('enter_phone_number')}
            />
            <Input
              type="password"
              label={t('confirm_password')}
              name="confirmPassword"
              control={control}
              placeholder={t('enter_password_again')}
            />
          </div>
        </div>
      </div>
      <div className="flex-center">
        <div className="mt-[25px] mb-[42px] w-[358px]">
          <Button
            type="submit"
            size="large"
            disabled={!isValid || !validPassword}
            fullWidth={true}
          >
            {t('sign_up')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateAccountBaseForm;
