import React, { forwardRef, InputHTMLAttributes } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { NumericFormat } from "react-number-format"
import classNames from "classnames";

import styles from "./Input.module.css";

export interface IInputProps<TFormData extends Record<string, any>>
  extends Pick<ControllerProps<TFormData>, 'control' | 'name' | 'rules' | 'defaultValue' | 'disabled'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlue?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  label?: string | React.ReactElement;
  onBlur?: (e: any) => void;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
  errorText?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  classnames?: string;
  endUnit?: string;
  unstyled?: boolean;
}

function Input<TFormData extends Record<string, any>>
({
   control,
   name,
   rules,
   label,
   defaultValue,
   type,
   classnames,
   error,
   errorText,
   onChange,
   onKeyUp,
   onKeyDown,
   onPaste,
   onBlue,
   endUnit,
   required,
   unstyled,
   ...props
 }: IInputProps<TFormData>): React.ReactElement {
  const numberInputOnWheelPreventChange = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({field, fieldState}) => (
        <div className='relative flex flex-col'>
          {label && (
            <label htmlFor={name} className={classNames(styles['label'])}>
              {label}{!!required && <span className="text-[#C73434]">*</span>}
            </label>
          )}
          <div className="relative">
            <NumericFormat
              thousandSeparator=" "
              onValueChange={(values: any) => {
                const { value } = values;
                field.onChange(value === '' ? value : parseInt(value));
              }}
              value={field.value || ''}
              id={name}
              step={1}
              onWheel={type === 'number' ? numberInputOnWheelPreventChange : undefined}
              onKeyDown={(e: any) => {
                onKeyDown?.(e);
              }}
              onBlur={(e: any) => {
                field.onBlur();
                onBlue?.(e)
              }}
              onKeyUp={onKeyUp}
              className={classNames({
                [styles['input']]: !unstyled,
                [classnames || '']: classnames,
                'border-error': error ?? !!fieldState.error,
                'border-[#e2e8f0]': !error && !fieldState.error && !unstyled,
              })}
              {...props}
            />
            {endUnit && (
              <div
                className="absolute top-[15px] right-[12px] font-Gilroy text-[14px] select-none text-[#333]"
              >
                {endUnit}
              </div>
            )}
          </div>
          {(errorText ?? fieldState.error?.message) && (
            <span
              className='absolute top-[82px] font-medium text-error text-sm max-w-full'>
              {errorText ?? fieldState.error?.message}
            </span>
          )}
        </div>
      )}
    />
  );
}

export default Input;

export const InputUncontrolled = forwardRef((props: InputHTMLAttributes<any>, ref: React.ForwardedRef<any>) => {
  return <input {...props} className={classNames(styles['input'], "border-[#e2e8f0]", props.className)} ref={ref}/>
})
