import { useTranslation } from "react-i18next";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

import useFormForgotPasswordEmail from "../hooks/useFormForgotPasswordEmail";

const ForgotPasswordEmailForm = () => {
  const { t } = useTranslation();

  const { handleSubmit, control, isValid } = useFormForgotPasswordEmail();

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-[318px]">
        <Input
          label={t("email_label")}
          name="email"
          control={control}
          placeholder={t("email_placeholder")}
        />
      </div>
      <div className="flex-center">
        <div className="mt-[46px] w-[318px]">
          <Button
            type="submit"
            size="large"
            disabled={!isValid}
            fullWidth={true}
          >
            {t("send_code")}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ForgotPasswordEmailForm;
