import React from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { postPropertyReject } from "../../../../../api/services/property/requests";

import { IPostRejectProperty } from "../../../../../api/services/property/types";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";
import Textarea from "../../../../../common/components/Textarea/Textarea";

interface IPropertyReject {
  reason: string;
}

interface RejectPropertyModalProps {
  id: string;
}

const RejectPropertyModal: React.FC<RejectPropertyModalProps> = ({ id }) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const defaultValues = {
    reason: '',
  }

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<IPropertyReject>({
    mode: "onBlur",
    defaultValues,
  });

  const reason = watch('reason');

  const onSubmit = async (value: IPropertyReject) => {
    const payload: IPostRejectProperty = {
      reason: value.reason,
    }

    try {
      setLoader(true)
      await postPropertyReject(id, payload);
      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-5 w-[572px]">
      <div className="flex justify-between items-center">
        <div className="text-[#333] text-[18px] leading-5 font-bold">{t('reject_property')}</div>
      </div>
      <div>
        <Textarea
          label={t('reject_reason')}
          name="reason"
          control={control}
          className="bg-[#f5f5f5]"
        />
      </div>
      <div className="flex items-center justify-end">
        <Button color="red" className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" disabled={!reason?.length} type="submit">{t('reject')}</Button>
      </div>
    </form>
  );
};

export default RejectPropertyModal;
