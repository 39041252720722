import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useGlobalModalStore from "../../stores/useGlobalModalStore";

import CrossIcon from "../../assets/icons/CrossIcon";

const Modal: React.FC = () => {
  const { isOpen, children, onClose } = useGlobalModalStore();

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current?.contains(event.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 bottom-0 right-0 flex-center bg-[#00000040]" onClick={handleClickOutside}>
      <div ref={modalRef} className="relative bg-white p-[25px] rounded-[10px] w-auto min-w-[400px] ">
        <div>
          {children}
        </div>
        <div className="absolute top-[-30px] right-0 cursor-pointer" onClick={onClose}>
          <CrossIcon width={24} height={24} color="#666" />
        </div>
      </div>
    </div>,
    document.body
  );
};
export default Modal;
