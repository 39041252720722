import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../../../common/components/Button/Button";

const RegistrationBlock = () => {
  const { t } = useTranslation();

  return (
    <div
      className="w-[676px] rounded-[20px] flex flex-col justify-between items-center h-full pt-[108px] pb-[82px] text-center text-white bg-registration-gradient-block bg-cover"
    >
      <div>
        <div className="text-[40px] font-bold">{t("welcome_message")}</div>
        <div className="text-[22px] mt-3">{t("command_center_message")}</div>
      </div>
      <div className="w-full px-[84px]">
        <div className="text-[40px] font-bold">{t("agent_account_question")}</div>
        <div className="text-[20px] mt-5">
          {t("registration_benefits")}
        </div>
        <div className="px-[54px] mt-[60px]">
          <Link to="/create-account">
            <Button color="white" size="large" fullWidth={true}>
              {t("register_now")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegistrationBlock;
