import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IPagination, IResponse } from "../../../common/types/response";
import { IProperty, IPropertyShort } from "../../../common/types/dto/property";
import {
  IDeletePropertyByIdGalleryPayload,
  IGetPropertyPayload,
  IPutPropertyByIdGalleryPayload,
  IPatchPropertyByIdPayload,
  IPostPropertyPayload,
  IPostRejectProperty,
  IPatchPropertyStatus,
} from "./types";

export const getProperty = async (payload?: IGetPropertyPayload) => {
  const response: AxiosResponse<IResponse<IPagination<IPropertyShort>>> = await axiosInstance.get("/property", {
    params: payload
  });
  return response.data.data;
}
export const getPropertyOwned = async (payload?: IGetPropertyPayload) => {
  const response: AxiosResponse<IResponse<IPagination<IPropertyShort>>> = await axiosInstance.get("/property/owned", {
    params: payload
  });
  return response.data.data;
}

export const postProperty = async (payload: IPostPropertyPayload) => {
  const response: AxiosResponse<IResponse<IPropertyShort>> = await axiosInstance.post("/property", payload);
  return response.data;
}

export const getPropertyById = async (id: string) => {
  const response: AxiosResponse<IResponse<IProperty>> = await axiosInstance.get(`/property/${id}`);
  return response.data.data;
}

export const getEditPropertyById = async (id: string) => {
  const response: AxiosResponse<IResponse<IProperty>> = await axiosInstance.get(`/property/${id}/edit`);
  return response.data.data;
}

export const patchPropertyById = async (id: string, payload: IPatchPropertyByIdPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch(`/property/${id}`, payload);
  return response.data.data;
}

export const putPropertyByIdGallery = async (id: string, payload: IPutPropertyByIdGalleryPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.put(`/property/${id}/gallery`, payload);
  return response.data.data;
}

export const deletePropertyByIdGallery = async (id: string, payload: IDeletePropertyByIdGalleryPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.delete(`/property/${id}/gallery`, {
    data: payload
  });
  return response.data.data;
}

export const postPropertyApprove = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(`/property/${id}/publish`);
  return response.data.data;
}

export const postPropertyReject = async (id: string, payload: IPostRejectProperty) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(`/property/${id}/reject`, payload);
  return response.data.data;
}

export const patchPropertyStatus = async (id: string, payload: IPatchPropertyStatus) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch(`/property/${id}/status`, payload);
  return response.data.data;
}

export const patchPropertyArchive = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch(`/property/${id}/archive`);
  return response.data.data;
}

export const postPropertyModeration = async (id: string, payload: any) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(`/property/${id}/moderation`, payload);
  return response.data.data;
}
