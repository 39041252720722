import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import classNames from "classnames";

interface IButtonsRadio {
  options: any[];
  control: Control;
  name: string;
  multi?: boolean;
}

const PropertyTagSelect: FC<IButtonsRadio> = ({options, control, name, multi = false}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <div className="flex gap-[10px] flex-wrap">
          {options?.map((o, index) => (
            <div
              key={o.id}
              className={classNames("flex-center cursor-pointer rounded-[8px] px-[12px] py-[8px] h-[40px] text-[14px] font-bold",
                {
                  "bg-[#f5f5f5] text-[#333]": multi ? !field.value?.includes(o.id) : field.value !== o.id,
                  "bg-blue text-white": multi ? field.value?.includes(o.id) : field.value === o.id,
                }
              )}
              onClick={() => {
                if (multi) {
                  if (field.value?.includes(o.id)) field.onChange(field.value?.filter((v: string) => v !== o.id));
                  else field.onChange([...field.value, o.id]);
                } else field.onChange(o.id);
              }}
            >
              {o.label}
            </div>
          ))}
        </div>
      )}
    />
  )
}

export default PropertyTagSelect;
