import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

import { postAuthPasswordReset } from "../../../api/services/auth/requests";

import { IForgotPasswordNewPassword } from "../types/form";

import { forgotPasswordNewPasswordFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

import useForgotPasswordStore from "../stores/useForgotPasswordStore";

const useFormForgotPasswordNewPassword = () => {
  const { t } = useTranslation();

  const { email, pin, setStep } = useForgotPasswordStore();
  const { setLoader } = useGlobalLoaderStore();

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    watch,
  } = useForm<IForgotPasswordNewPassword>({
    mode: "onBlur",
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(forgotPasswordNewPasswordFormSchema),
  });

  const password = watch("password");

  const onSubmit = async ({password}: IForgotPasswordNewPassword) => {
    try {
      setLoader(true);
      await postAuthPasswordReset({
        email,
        newPassword: password,
        otp: pin,
      });
      setStep("success");
    }
    catch (error: any) {
      const message = error?.response?.data?.message || t('request_failed');
      alert(message);
    }
    finally {
      setLoader(false);
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
    password,
  }
}

export default useFormForgotPasswordNewPassword;
