import { create } from "zustand";

import { TCreateAccountPageStep } from "../types/step";
import { ICreateAccountBase } from "../types/form";

interface ICreateAccountStore {
  step: TCreateAccountPageStep;
  data: {
    base: ICreateAccountBase,
  };
  setStep: (newStep: TCreateAccountPageStep) => void;
  handleBaseStep: (baseData: ICreateAccountBase) => void;
  resetStore: () => void;
}

const useCreateAccountStore = create<ICreateAccountStore>((set) => ({
  step: "base",
  data: {
    base: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      legalName: '',
      address: '',
      ic: '',
      companyPhone: '',
    }
  },
  setStep: (newStep) => set((prevState) => ({
    ...prevState,
    step: newStep,
  })),
  handleBaseStep: (baseData: ICreateAccountBase) => set((prevState) => ({
    step: "pin",
    data: {
      ...prevState.data,
      base: baseData,
    }
  })),
  resetStore: () => set (() => ({
    step: "base",
    data: {
      base: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        legalName: '',
        address: '',
        ic: '',
        companyPhone: '',
      }
    },
  })),
}))


export default useCreateAccountStore;
