import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { postOtpEmailConfirmSend } from "../../../api/services/otp/requests";

import useCreateAccountStore from "../stores/useCreateAccountStore";

import PinInput from "../../../common/components/PinInput/PinInput";

import useFormCreateAccountPin from "../hooks/useFormCreateAccountPin";

const CreateAccountPinForm = () => {
  const { t } = useTranslation();

  const { data: { base: { email } } } = useCreateAccountStore();
  const {
    control,
  } = useFormCreateAccountPin();

  const [counter, setCounter] = useState(60);

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }

  useEffect(() => {
    startCounter();
  }, []);

  const resendCode = async () => {
    if(counter > 0) return;
    setCounter(60);
    try {
      await postOtpEmailConfirmSend({ email });
      startCounter();
    }
    catch (error){
      alert(error)
    }
  };

  return (
    <div>
      <PinInput
        length={4}
        defaultValue={""}
        name="pin"
        control={control}
      />
      <div className={classNames(
        "text-[15px] text-[#666] text-center leading-[140%] mt-[32px]", {
        "cursor-pointer": counter <= 0,
      })} onClick={resendCode}>
        {t('resend_code')} {counter >= 0 && counter}
      </div>
    </div>
  );
}

export default CreateAccountPinForm;
