import * as zod from "zod"

export const editAccountFormSchema = zod.object({
  name: zod.string().min(1),
  // email: zod.string().email("Enter valid email"),
  phone: zod.string().min(1),
  businessType: zod.string(),
  legalName: zod.string(),
  address: zod.string(),
  ic: zod.string(),
  // city: zod.string(),
  // dic: zod.string(),
  // postalCode: zod.number(),
  companyPhone: zod.string(),
  description: zod.string(),
  web: zod.string(),
  instagram: zod.string(),
  twitter: zod.string(),
  facebook: zod.string(),
  tikTok: zod.string(),
});

export const changePasswordFormSchema = zod.object({
  currentPassword: zod.string(),
  newPassword: zod.string().min(8),
  confirmNewPassword: zod.string().min(8),
}).refine(
  (values) => {
    return values.newPassword === values.confirmNewPassword;
  },
  {
    message: "Passwords must match!",
    path: ["confirmNewPassword"],
  }
);

export const changeEmailFormSchema = zod.object({
  email: zod.string().email("Enter valid email"),
});