import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { postPropertyActive } from "../../../../../api/services/property/requests";

import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";

import Button from "../../../../../common/components/Button/Button";
import {postPropertyModeration} from "../../../../../api/services/property/requests";


interface ActivateModalFormProps {
  id: string;
}

const ActivateModalForm: React.FC<ActivateModalFormProps> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const movePropertyToActive = async () => {
    try {
      const payload = {
        autoBuyMissingPublications: false,
      }
      setLoader(true);
      await postPropertyModeration(id, payload);
      onClose();
      navigate('/properties')
    } catch (error: any) {
      setError(error.response?.data?.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="grid gap-[25px] w-[428px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-2">{t('change_status')}</div>
        <div>{t('return_to_active')}</div>
      </div>
      <div className="flex items-center justify-end gap-[30px]">
        <div onClick={onClose} className="text-[16px] text-[#006CFB] font-medium cursor-pointer">{t('not_now')}</div>
        <Button className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" onClick={movePropertyToActive}>{t('publish')}</Button>
      </div>
    </div>
  );
};

export default ActivateModalForm;
