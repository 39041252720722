import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useCreateAccountStore from "../../features/create-account/stores/useCreateAccountStore";

import CreateAccountBaseForm from "../../features/create-account/components/CreateAccountBaseForm";
import CreateAccountPinForm from "../../features/create-account/components/CreateAccountPinForm";

import Logo from "../../common/assets/images/Logo";
import ChevronIcon from "../../common/assets/icons/ChevronIcon";

const CreateAccountPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { step, setStep, data: { base: { email } }, resetStore } = useCreateAccountStore((state) => ({...state}));

  //todo make component
  const stepHeader = useMemo(() => {
    switch (step) {
      case "base":
        return t('create_account_step_1_title');
      case "pin":
        return t('create_account_step_2_title');
    }
  }, [step]);

  //todo make component
  const stepDescription = useMemo(() => {
    switch (step) {
      case "base":
        return t('create_account_step_1_desc');
      case "pin":
        return t('create_account_step_2_desc', { email });
    }
  }, [step, email]);

  const handleBackClick = () => {
    switch (step) {
      case "base":
        navigate("/");
        break;
      case "pin":
        setStep("base");
        break;
    }
  }

  useEffect(() => {
    resetStore()
  }, [])

  return (
    <div className="flex flex-col items-center pt-[35px] relative overflow-y-auto h-full pb-[26px] px-[85px]">
      <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
        <div className="flex items-center gap-1">
          <ChevronIcon/>
          <div>{t('back')}</div>
        </div>
      </div>
      <div className="w-[140px] h-[49px]">
        <Link to="/">
          <Logo width={140} height={49} />
        </Link>
      </div>
      <div className="flex flex-col items-center pt-[35px]">
        <h2 className="text-2xl text-[#333333] font-bold">{stepHeader}</h2>
        <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4">{stepDescription}</div>
      </div>
      <div className="mt-[50px]">
        {step === "base" && <CreateAccountBaseForm/>}
        {step === "pin" && <CreateAccountPinForm/>}
      </div>
    </div>
  );
}

export default CreateAccountPage;
