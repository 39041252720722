import React, {useRef, useState, useEffect, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { getNotificationOwned, patchNotificationRead } from "../../../api/services/notifications/requests";

import { IGetNotificationOwnedPayload } from "../../../api/services/notifications/types";

import { TPropertyStatus } from "../../../common/types/dto/property";

import { TNotifications } from "../types/notification";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import DropdownSimple from "../../../common/components/Dropdown/DropdownSimple";
import Pagination from "../../../common/components/Pagination/Pagination";

import NotificationIcon from "../../../common/assets/icons/NotificationIcon";
import NewsIcon from "../../../common/assets/icons/NewsIcon";
import UserIcon from "../../../common/assets/icons/UserIcon";

const LIMIT = 20;

const colors: Record<TPropertyStatus, string> = {
  'ACTIVE': '#48bb78',
  'DRAFT': '#485abb',
  'IN_MODERATION': '#d6d145',
  'UNPAID': '#9148BB',
  'REJECTED': '#c73434',
  'ARCHIVED': '#a9a9a9',
}

const NewsNotification = ({ title, date, message, isRead }: { title: string, date: string, message: string, isRead: boolean }) => {
  return (
    <div className={`py-4 px-3 rounded-[10px] font-medium ${!isRead ? 'bg-[#F2F8FF]' : 'bg-[#FAFAFA]'}`}>
      <div className="grid gap-2.5">
        <div className="flex items-center gap-2.5">
          <div className="flex-center w-[50px] h-[50px] min-w-[50px] bg-[#F2F8FF] rounded-[8px]">
            <NewsIcon />
          </div>
          <div>
            <div className="mb-1">{title}</div>
            <div className="text-[12px]">{date}</div>
          </div>
        </div>
        <div className="text-[12px] text-[#666666]">
          {message}
        </div>
      </div>
    </div>
  )
}

const PropertyNotification = ({ title, date, message, isRead, onClick, propertyStatus }: { title: string, date: string, message: string, isRead: boolean, onClick?: () => void, propertyStatus?: TPropertyStatus }) => {
  return (
    <div onClick={onClick} className={`py-4 px-3 rounded-[10px] font-medium cursor-pointer ${!isRead ? 'bg-[#F2F8FF]' : 'bg-[#FAFAFA]'}`}>
      <div className="grid gap-2.5">
        <div>
          <div className="mb-1"><span className="inline-block w-2 h-2 min-w-2 rounded-full mr-1" style={{ background: colors[propertyStatus || 'DRAFT']}} />{title}</div>
          <div className="text-[12px]">{date}</div>
        </div>
        <div className="text-[12px] text-[#666666]">
          {message}
        </div>
      </div>
    </div>
  )
}

const AccountNotification = ({ title, date, message, isRead }: { title: string, date: string, message: string, isRead: boolean }) => {
  return (
    <div className={`py-4 px-3 rounded-[10px] font-medium ${!isRead ? 'bg-[#F2F8FF]' : 'bg-[#FAFAFA]'}`}>
      <div className="grid gap-2.5">
        <div className="flex items-center gap-2.5">
          <div className="flex-center w-[50px] h-[50px] min-w-[50px] bg-[#F2F8FF] rounded-[8px]">
            <UserIcon />
          </div>
          <div>
            <div className="mb-1">{title}</div>
            <div className="text-[12px]">{date}</div>
          </div>
        </div>
        <div className="text-[12px] text-[#666666]">
          {message}
        </div>
      </div>
    </div>
  )
}

const NotificationsBlock = () => {
  const [page, setPage] = useState<number>(1);
  const [visibleNotificationIds, setVisibleNotificationIds] = useState<string[]>([]);

  const dropdownRef = useRef<any>(null);
  const notificationRefs = useRef<Map<string, HTMLElement>>(new Map());

  const navigate = useNavigate();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data, refetch } = useQuery(["agent_notifications", page], async () => {
    const payload: IGetNotificationOwnedPayload = {
      page,
      limit: LIMIT,
    };

    setLoader(true);
    const data: TNotifications = await getNotificationOwned(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response?.data?.message);
      setLoader(false);
    }
  });

  const { mutate: markAsRead } = useMutation(patchNotificationRead, {
    onSuccess: async () => {
      await refetch();
    },
  });

  const handleVisibleNotification = useCallback((id: string) => {
    setVisibleNotificationIds(prev => prev.includes(id) ? prev : [...prev, id]);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const ref = entry.target;
            if (ref) {
              const id = ref.getAttribute("id");
              const isRead = ref.getAttribute("data-read");
              if (id && isRead === 'false') {
                handleVisibleNotification(id);
              }
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    notificationRefs.current.forEach((ref, id) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [data, handleVisibleNotification]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (visibleNotificationIds.length > 0) {
        const uniqueIds = Array.from(new Set(visibleNotificationIds));
        markAsRead({ notificationIds: uniqueIds });
        setVisibleNotificationIds([]);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [visibleNotificationIds, markAsRead]);

  const isUnreadMessages = !!data?.items?.find(item => !item.isRead);

  const closeDropdown = () => {
    if (!dropdownRef.current) return;

    dropdownRef.current.closeDropdown();
  };

  const handleClick = (id?: string) => {
    if (!id) return;

    navigate(`/property/${id}`);
    closeDropdown();
  };

  const notificationsMap = data?.items?.map((notification) => {
    const { id, title, message, isRead, type, createdAt, meta: { propertyStatus, propertyId } } = notification;

    return (
      <div
        ref={(el) => notificationRefs.current.set(id, el!)}
        key={id}
        id={id}
        data-read={isRead}
      >
        {type === 'NEWS' && (
          <NewsNotification
            title={title}
            message={message}
            date={createdAt}
            isRead={isRead}
          />
        )}
        {type === 'PROPERTY' && (
          <PropertyNotification
            title={title}
            message={message}
            date={createdAt}
            isRead={isRead}
            onClick={() => handleClick(propertyId)}
            propertyStatus={propertyStatus}
          />
        )}
        {type === 'ACCOUNT' && (
          <AccountNotification
            title={title}
            message={message}
            date={createdAt}
            isRead={isRead}
          />
        )}
      </div>
    );
  });

  const totalPages = !!data?.total && data.total > data.limit && Math.ceil(data.total / data.limit);

  return (
    <DropdownSimple
      ref={dropdownRef}
      title={
        <div className="relative">
          <NotificationIcon/>
          {isUnreadMessages && <div className="absolute top-[2px] right-[2px] w-2 h-2 rounded-full bg-[#EB5757]"/>}
        </div>
      }
      menuClass="left-auto right-0"
      maxHeight={620}
    >
      <div className="grid gap-5 w-[400px] p-6">
        {notificationsMap}
        {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)}/>}
      </div>
    </DropdownSimple>
  )
}

export default NotificationsBlock;
