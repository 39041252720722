import { useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { postOtpEmailConfirmSend } from "../../api/services/otp/requests";

import { TVerifyEmailPageStep } from "../../features/verify-email/types/step";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";

import Button from "../../common/components/Button/Button";

import VerifyEmailPinForm from "../../features/verify-email/components/VerifyEmailPinForm";

import ChevronIcon from "../../common/assets/icons/ChevronIcon";
import Logo from "../../common/assets/images/Logo";

const VerifyEmailPage = () => {
  const [step, setStep] = useState<TVerifyEmailPageStep>("send-code");

  const navigate = useNavigate();
  const [searchParams]  = useSearchParams();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();

  const email = atob(searchParams.get("email") || "");

  const stepHeader = useMemo(() => {
    switch (step) {
      case "send-code":
        return t("verify_your_email");
      case "pin":
        return t("verify_your_code");
    }
  }, [step, t]);

  const stepDescription = useMemo(() => {
    switch (step) {
      case "send-code":
        return t("email_not_verified", { email });
      case "pin":
        return t("enter_code", { email });
    }
  }, [step, email, t]);

  const handleBackClick = () => {
    switch (step) {
      case "send-code":
        navigate("/");
        break;
      case "pin":
        setStep("send-code");
        break;
    }
  };

  const handleSendCodeClick = async () => {
    try {
      setLoader(true);
      await postOtpEmailConfirmSend({ email });
      setStep("pin");
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="flex flex-col items-center pt-[50px] relative overflow-y-auto h-full pb-[50px]">
      <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
        <div className="fixed">
          <div className="flex items-center gap-1">
            <ChevronIcon />
            <div>{t("back")}</div>
          </div>
        </div>
      </div>
      <div className="w-[160px] h-[56px]">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="flex flex-col items-center pt-[54px]">
        <h2 className="text-2xl font-bold mt-0.5">{stepHeader}</h2>
        <div className="text-[15px] text-[#666] text-center leading-[140%] mt-4 w-[508px]">
          {stepDescription}
        </div>
      </div>
      {step === "send-code" && (
        <div className="w-[358px] mt-[62px]">
          <Button fullWidth={true} onClick={handleSendCodeClick}>
            {t("send_code")}
          </Button>
        </div>
      )}
      {step === "pin" && (
        <div className="mt-[62px]">
          <VerifyEmailPinForm email={email} />
        </div>
      )}
    </div>
  );
}

export default VerifyEmailPage;
