import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getPaymentPaymentSessions } from "../../../api/services/payment/requests";

import { IGetPaymentPaymentSessions } from "../../../api/services/payment/types";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import Pagination from "../../../common/components/Pagination/Pagination";

import Visa from "../../../common/assets/icons/Visa";
import MasterCard from "../../../common/assets/icons/MasterCard";

const LIMIT = 10;

const TransactionsHistory = () => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data = {} } = useQuery(["transactions_history", page], async () => {
    const payload: IGetPaymentPaymentSessions = {
      page,
      limit: LIMIT,
    };

    setLoader(true);
    const data = await getPaymentPaymentSessions(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response?.data?.message);
      setLoader(false);
    }
  });

  const transactionsMap = data.items?.map((item: any) => {
    const { id, paymentMethod, paid, createdAt, paymentInvoice } = item;

    const method = paymentMethod?.paymentMethodType || '';
    const cardType = paymentMethod?.cardBrand || '';
    const cardIcon = cardType === 'visa' ? <Visa /> : <MasterCard />;
    const cardLast4 = paymentMethod?.cardLast4 || '';

    const formattedDate = new Intl.DateTimeFormat('default', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(createdAt));

    return (
      <React.Fragment key={id}>
        <div className="flex items-center gap-4">
          {!!cardType && <div>{cardIcon}</div>}
          <div><span className="capitalize">{method.toLowerCase()}</span> <span className="capitalize">{cardType}</span>  <span>{!!cardLast4 ? `** ${cardLast4}` : ''}</span></div>
        </div>
        <div className="text-[10px] text-[#A0AEC0] font-bold text-center">{id}</div>
        <div className="text-[#48BB78] text-center">{!!paid && t('paid')}</div>
        <div className="text-[#666666] text-center">{formattedDate}</div>
        <div className="text-[#006CFB] text-right">
          {!!paymentInvoice?.invoicePdf && <a href={paymentInvoice.invoicePdf} download target="_blank" rel="noopener noreferrer">
            {t('download')}
          </a>}
        </div>
      </React.Fragment>
    )
  });

  const totalPages = !!data.total && data.total > data.limit && Math.ceil(data.total / data.limit);

  return (
    <div>
      <div className="font-medium mb-6">{t('transactions_history')}</div>
      <div className="grid grid-cols-[2fr_250px_1fr_1fr_1fr] justify-between items-center text-[10px] text-[#A0AEC0] font-bold pb-[6px] border-b border-[#E2E8F0] mb-4">
        <div>{t('method')}</div>
        <div className="text-center">{t('transaction_id')}</div>
        <div className="text-center">{t('status')}</div>
        <div className="text-center">{t('date')}</div>
        <div className="text-right">{t('receipt')}</div>
      </div>
      <div className="grid grid-cols-[2fr_250px_1fr_1fr_1fr] justify-between items-center gap-y-4">
        {transactionsMap}
      </div>
      {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)}/>}
    </div>
  )
}

export default TransactionsHistory;
