import React from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";

import { getEditPropertyById } from "../../api/services/property/requests";
import { getCategorySchemaById } from "../../api/services/category/requests";

import { getSubpageName } from "../../features/property/helpers/propertyName";

import { EUserRole } from "../../common/types/dto/user";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../common/stores/useAuthStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import PageHeader from "../../common/components/PageHeader/PageHeader";

import PropertyStatus from "../../features/property/components/PropertyStatus/PropertyStatus";
import PropertyPreview from "../../features/property/components/PropertyPreview";
import PropertyActionsAgent from "../../features/property/components/PropertyActions/PropertyActionsAgent";
import PropertyActionsAdmin from "../../features/property/components/PropertyActions/PropertyActionsAdmin";

const PropertyPage = () => {
  let { id } = useParams();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { user, impersonationToken } = useAuthStore();

  const { data } = useQuery(["getEditPropertyById", "getCategorySchemaById", id], async () => {
    setLoader(true);
    const property = await getEditPropertyById(id!);
    if (!property.category?.id) {
      setError('Unknown property category');
      setLoader(false);

      return null;
    }
    const categorySchema = await getCategorySchemaById(property.category.id);
    setLoader(false);
    return {
      property,
      categorySchema,
    };
  }, {
    enabled: !!id,
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const property = data?.property;
  const categorySchema = data?.categorySchema;
  const subpageName = getSubpageName(property);

  if (!property) return null;

  return (
    <div className="p-[20px_18px_16px_40px] min-h-full">
      <PageHeader
        backButton="/properties"
        start={
          <>
            <div>{subpageName}</div>
            <PropertyStatus status={property.status}/>
          </>
        }
        end={
          <>
            {user?.role === EUserRole.Admin && !impersonationToken ? <PropertyActionsAdmin id={id} status={property.status} /> : <PropertyActionsAgent id={id} status={property.status} property={property} categorySchema={categorySchema} /> }
          </>
        }
      />
      {property?.status === 'REJECTED' && property.rejectReason && (
        <div className="bg-[#E53E3E1A] whitespace-pre-wrap rounded-[8px] p-2.5 my-5">
          {property.rejectReason}
        </div>
      )}
      <CardWrapper className="mt-[12px] !p-[20px]">
        <PropertyPreview
          property={property}
          categorySchema={categorySchema}
        />
      </CardWrapper>
    </div>
  );
}

export default PropertyPage;
