import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"

import { IPostAuthRegisterAgentPayload } from "../../../api/services/auth/types";

import { postAuthAgentRegister } from "../../../api/services/auth/requests";
import { postOtpEmailConfirmSend } from "../../../api/services/otp/requests";

import { ICreateAccountBase } from "../types/form";

import { createAccountBaseFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useCreateAccountStore from "../stores/useCreateAccountStore";

const defaultValue = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: undefined,
  legalName: '',
  address: '',
  ic: '',
  companyPhone: '',
}

const useFormCreateAccountBase = () => {
  const { data: { base }, handleBaseStep } = useCreateAccountStore((state) => state);
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    watch,
  } = useForm<ICreateAccountBase>({
    mode: 'onBlur',
    defaultValues: base || defaultValue,
    resolver: zodResolver(createAccountBaseFormSchema),
  });

  const password = watch("password");

  const onSubmit = async (value: ICreateAccountBase) => {
    const payload: IPostAuthRegisterAgentPayload = {
      email: value.email,
      password: value.password,
      phone: value.phone,
      name: value.name,
      profile: {
        legalName: value.legalName,
        address: value.address,
        ic: value.ic,
        phone: value.companyPhone,
      },
    }
    try {
      setLoader(true)
      await postAuthAgentRegister(payload);
      await postOtpEmailConfirmSend({email: value.email});
      handleBaseStep(value);
    } catch (error: any) {
      setError(error?.response?.data?.message || 'Request failed')
    } finally {
      setLoader(false)
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    password,
    isValid,
  };
}

export default useFormCreateAccountBase;
