import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ChevronIcon from "../common/assets/icons/ChevronIcon";
import Logo from "../common/assets/images/Logo";
import ExtLinkIcon from "../common/assets/icons/ExtLinkIcon";

const DocumentsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center pt-[35px] relative overflow-y-auto h-full pb-[26px] px-[85px]">
      <div className="absolute top-[36px] left-[36px] text-blue text-[18px] cursor-pointer" onClick={handleBackClick}>
        <div className="flex items-center gap-1">
          <ChevronIcon />
          <div>{t("back")}</div>
        </div>
      </div>
      <div className="w-[140px] h-[49px]">
        <Link to="/">
          <Logo width={140} height={49} />
        </Link>
      </div>
      <div className="flex flex-col items-center pt-[35px]">
        <h2 className="text-2xl text-[#333333] font-bold">{t("documents_and_files")}</h2>
      </div>
      <div className="grid gap-3 text-[#26203B] mt-[60px]">
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/Complaints_procedure.pdf" download="Complaints_procedure.pdf">{t("complaints_procedure")}</a>
          <a href="/Complaints_procedure.pdf" target="_blank" rel="noopener noreferrer"><ExtLinkIcon /></a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/GTC_broker.pdf" download="GTC_broker.pdf">{t("gtc_broker")}</a>
          <a href="/GTC_broker.pdf" target="_blank" rel="noopener noreferrer"><ExtLinkIcon /></a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/GTC_users.pdf" download="GTC_users.pdf">{t("gtc_users")}</a>
          <a href="/GTC_users.pdf" target="_blank" rel="noopener noreferrer"><ExtLinkIcon /></a>
        </div>
        <div className="flex-center gap-1 border-b border-[#00000026]">
          <a href="/personal_data.pdf" download="personal_data.pdf">{t("personal_data")}</a>
          <a href="/personal_data.pdf" target="_blank" rel="noopener noreferrer"><ExtLinkIcon /></a>
        </div>
      </div>
    </div>
  );
}

export default DocumentsPage;
