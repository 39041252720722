import React, { useEffect, useState } from 'react';
import classNames from "classnames";

import { postOtpEmailChangeSend } from "../../../../../api/services/otp/requests";

import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";

import useChangeEmailStore from "../../../stores/useChangeEmailStore";

import PinInput from "../../../../../common/components/PinInput/PinInput";

import useFormChangeEmailOTP from "../../../hooks/useFormChangeEmailOTP";

const ChangeEmailOTPModalForm: React.FC = () => {
  const [counter, setCounter] = useState(60);

  const { setError } = useGlobalErrorStore();
  const { email } = useChangeEmailStore();

  const {
    control,
  } = useFormChangeEmailOTP();

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1)
    }, 1000);
  }

  const resendCode = async () => {
    if(counter > 0) return;
    setCounter(60);
    try {
      await postOtpEmailChangeSend({newEmail: email});
      startCounter();
    }
    catch (error: any){
      setError(error?.response?.data?.message || '')
    }
  };

  useEffect(() => {
    startCounter();
  }, []);

  return (
      <div className="grid gap-6 w-[572px]">
        <div>
          <h2 className="text-[#333] text-[18px] leading-5 font-medium mb-[10px]">Change email</h2>
          <div className="text-[14px] text-[#666]">Enter otp code sent to your email.</div>
        </div>
        <div>
          <PinInput
            length={4}
            defaultValue={""}
            name="otp"
            control={control}
          />
          <div className={classNames(
            "text-[15px] text-[#666] text-center leading-[140%] mt-[32px]", {
              "cursor-pointer": counter <= 0,
            })} onClick={resendCode}>
            Resend code {counter >= 0 && counter}
          </div>
        </div>
      </div>
  );
};

export default ChangeEmailOTPModalForm;
