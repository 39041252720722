import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { postAuthEmailConfirm } from "../../../api/services/auth/requests";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import useCreateAccountStore from "../stores/useCreateAccountStore";

const useFormCreateAccountPin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setAccessToken, setRefreshToken } = useAuthStore();

  const { email, resetStore } = useCreateAccountStore((state) => ({
    email: state.data.base.email,
    resetStore: state.resetStore,
  }));

  const {
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      pin: ""
    }
  });

  const pin = watch("pin");

  const onSubmit = async ({pin}: { pin: string }) => {
    try {
      setLoader(true);
      const {accessToken, refreshToken} = await postAuthEmailConfirm({otp: pin, email});
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      resetStore()
      setTimeout(() => {
        navigate("/");
      }, 200)
    }
    catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'))
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (pin.length === 4) handleSubmit(onSubmit)();
  }, [pin]);

  return {
    control,
  }
}

export default useFormCreateAccountPin;
