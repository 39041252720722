import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Input from "../../../common/components/Input/Input";
import Button from "../../../common/components/Button/Button";

import useFormLogin from "../hooks/useFormLogin";

const LoginForm = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    isValid,
  } = useFormLogin();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-5">
        <Input
          label={t("email_label")}
          name="email"
          control={control}
          placeholder={t("email_placeholder")}
        />
        <Input
          type="password"
          label={(
            <div className="flex justify-between items-center">
              <div>{t("password_label")}</div>
              <div className="text-[12px] text-[#9c9aa5] underline">
                <Link to="/forgot-password">{t("forgot_password")}</Link>
              </div>
            </div>
          )}
          name="password"
          control={control}
          placeholder={t("password_placeholder")}
        />
      </div>
      <div className="mt-[54px]">
        <Button
          type="submit"
          size="large"
          disabled={!isValid}
          fullWidth
        >
          {t("sign_in")}
        </Button>
      </div>
    </form>
  );
}

export default LoginForm;
