import React from "react";
import { RouteObject } from "react-router";

import AuthLayout from "../pages/auth/AuthLayout";
import BaseLayout from "../pages/base/BaseLayout";
import DashboardPage from "../pages/base/DashboardPage";
import PropertiesPage from "../pages/base/PropertiesPage";
import BalancePage from "../pages/base/BalancePage";
import ProductsPage from "../pages/base/ProductsPage";
import MessagesPage from "../pages/base/MessagesPage";
import AccountPage from "../pages/base/AccountPage";
// import SupportPage from "../pages/base/SupportPage";
import PropertyPage from "../pages/base/PropertyPage";
import PropertyCreatePage from "../pages/base/PropertyCreatePage";
import PropertyEditPage from "../pages/base/PropertyEditPage";
import DocumentsPage from "../pages/Documents";
import NotFoundPage from "../pages/NotFoundPage";

const routesAgent: RouteObject[] = [
  {
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/properties",
        element: <PropertiesPage />,
      },
      {
        path: "/property/:id",
        element: <PropertyPage />,
      },
      {
        path: "/property/create",
        element: <PropertyCreatePage />,
      },
      {
        path: "/property/:id/edit",
        element: <PropertyEditPage />,
      },
      {
        path: "/balance",
        element: <BalancePage />,
      },
      {
        path: "/products",
        element: <ProductsPage />,
      },
      {
        path: "/messages",
        element: <MessagesPage />,
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
      // {
      //   path: "/support",
      //   element: <SupportPage />,
      // },
      {
        path: "*",
        element: <NotFoundPage/>
      },
    ]
  },
  {
    element: <AuthLayout borderColor="blue"/>,
    children: [
      {
        path: "/documents",
        element: <DocumentsPage/>,
      },
    ]
  }
]

export default routesAgent;
